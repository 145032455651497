import { activateFormSubmit, addMultipleEventListener, ready } from 'ext/utils'
import LibCalculator from 'global/lib/calculator'
import LibDataBehaviorCollector from 'global/lib/behavioral_variables'
import LibFirstPageTimer from 'global/lib/first_page_timer'
import LibSubscribeNotifications from 'global/lib/subscribe_notifications'
import LibCheckAgreementNumber from 'global/lib/check_agreement_number'
import Cookies from 'js-cookie'
import 'jquery-validation'
import 'global/lib/jquery_validation'
import 'bootstrap-sass/assets/javascripts/bootstrap/modal'
import LibUpdateParamsAsync from 'global/lib/update_params_async'
import LibUpdateJeffParams from 'global/lib/update_jeff_params'
import LibApplicationFields from 'global/lib/application_fields'
const { remove, set } = Cookies
const { recaptchaSiteKey } = window.gon

const viewsHomeIndex = container => {
  remove('progressBarVal')

  $('[role="showHero"]').click(e => {
    $(e.currentTarget).fadeOut('fast', () => {
      $('[role="heroBlock"]').addClass('action')
      $('[role="fullName"]').focus()
    })
  })

  const form = container.querySelector('form[role="form"]')
  const scrollTo = container.querySelector('.scrolltoelem')

  if (scrollTo) {
    scrollTo.addEventListener('click', e => {
      e.preventDefault()
      const target = $(e.currentTarget).data('scrollto')
      $('html, body').animate({
        scrollTop: $(target).offset().top
      }, { duration: 500 })
    })
  }

  const validator = validate($(form))

  if (form) {
    form.addEventListener('ajax:success', (event, _status, _xhr) => {
      const data = event.detail[0]
      if (data.show_modal) {
        showModal(data.html)
      } else if (data.url) {
        if (typeof grecaptcha !== 'undefined') {
          grecaptcha.execute(recaptchaSiteKey, { action: 'homepage' }).then(token => {
            set('recaptchaToken', token)
            window.location = data.url
          }, err => window.location = data.url)
        } else {
          window.location = data.url
        }
      } else {
        if (!data.messages) return
        let errors
        if (data.messages.mobile_phone)
          errors = { 'application[mobile_phone]': data.messages.mobile_phone }
        if (data.messages.referral_code)
          errors = { 'application[referral_code]': data.messages.referral_code }
        if (data.messages.privacy_policy_acceptance)
          errors = { 'application[privacy_policy_acceptance]': data.messages.privacy_policy_acceptance }

        validator.showErrors(errors)
      }
    })
  }

  $('[role="checkValid"]').on('change', e => {
    const target = $(e.currentTarget)

    const valLength = target.val().length
    const invalid = target.attr('aria-invalid')

    if (invalid === 'false' && valLength > 0)
      target.closest('div').addClass('input__valid')
    else
      target.closest('div').removeClass('input__valid')
  })

  $('#application_mobile_phone').attr('inputmode', 'numeric')

  if (!/cayvang|localhost/.test(document.referrer)) localStorage.setItem('ref', document.referrer)

  localStorage.removeItem('afp')

  const pawnshopContainer = document.querySelector('[role="agreementNumberContailner"]')
  if (pawnshopContainer) new LibCheckAgreementNumber(pawnshopContainer)

  window.addEventListener('load', callbackFunc)
  window.addEventListener('resize', callbackFunc)
  window.addEventListener('scroll', callbackFunc)
  const { freshChatUrl, freshChatToken } = window.gon
  window.fcWidget?.init({ token: freshChatToken, host: freshChatUrl })
}

const showModal = data => {
  $('[role="modalContent"]').html(data)
  $('[role="modal"]').modal('show')
}

const isElementInViewport = el => {
  const rect = el.getBoundingClientRect()
  return (rect.top >= 0) && (rect.left >= 0) &&
    (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) &&
    (rect.right <= (window.innerWidth || document.documentElement.clientWidth))
}

const callbackFunc = () => {
  const items = document.querySelectorAll('.timeline li')

  let i = 0
  while (i < items.length) {
    if (isElementInViewport(items[i])) items[i].classList.add('in-view')
    i++
  }
}

const validate = form => {
  return form.validate({
    errorClass: 'form_float__error',
    highlight: (element, _errorClass, _validClass) => {
      $(element).closest('.form_float__group').addClass('form_float__group-error')
      $(element).siblings('.form_float__error').remove()
    },
    unhighlight: (element, _errorClass, _validClass) => {
      $(element).closest('.form_float__group').removeClass('form_float__group-error')
      $(element).siblings('.form_float__error').remove()
    },
    rules: {
      'application[mobile_phone]': {
        required: true,
        exactlength: [10, 10],
        sameNumbers: true,
        dontNumbersConsecutive: true,
        not_landline_phone: true,
        not_double_zero: true,
        not_first_one: true
      },
      'application[privacy_policy_acceptance]': {
        acceptance: true
      },
      'application[promo_code]': {
        lettersdigits: true
      },
      'application[referral_code]': {
        lettersdigits: true,
        maxSymbols: 9,
        minSymbols: 9
      }
    }
  })
}

ready(() => {
  const container = document.querySelector('[role="container"]')

  new LibCalculator(container)
  new LibDataBehaviorCollector(container)
  new LibFirstPageTimer()
  new LibSubscribeNotifications()
  new LibUpdateParamsAsync(container, { step: 0 })
  new LibUpdateJeffParams()
  new LibApplicationFields(container)

  viewsHomeIndex(container)

  const form = container.querySelector('form')
  setTimeout(() => activateFormSubmit(form, 'home'), 0)
  addMultipleEventListener(form, ['change', 'keyup'], () => activateFormSubmit(form, 'home'))
})
