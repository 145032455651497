const CHECK_OBJECT = 'application'
const FORBIDDEN_PARAMS = {
  input: [
    'bank_id',
    'card_bank_id'
  ],
  select: [
    'bank_account_number',
    'bank_card_number'
  ]
}

export default class LibUpdateParamsAsync {
  constructor(container, options) {
    this.step = options.step
    container.addEventListener('change', event => this.changeHandler.call(this, event), false)

    // FIXME: This shit needed because of select2 kills general events.
    //  It needs to be replaced with select2 analogue without jquery
    const selects = $(container).find('select')
    selects.on('select2:close', event => this.changeHandler.call(this, event))
  }

  changeHandler(event) {
    const target = event.target
    const permittedParams = Object.keys(FORBIDDEN_PARAMS).map(type => this.permit(target, type))

    if (permittedParams.some(Boolean)) return false

    this.updateParamsAsync(target)
  }

  permit(target, type) {
    return target.matches(type) &&
      FORBIDDEN_PARAMS[type].map(param => `${CHECK_OBJECT}[${param}]`).includes(target.name)
  }

  updateParamsAsync(input) {
    const attrName = input.getAttribute('name')
    let currentValue

    if (attrName === 'application[is_email_missing]')
      currentValue = input.checked
    else
      currentValue = input.value

    this.updateBrowserStorage(attrName, currentValue)

    if (attrName === 'application[mobile_phone]' || this.step === 0 || !currentValue) return

    const data = { [attrName]: currentValue }

    // HACK: For empty selected values after refresh page
    if (attrName === 'application[living_city_id]' && currentValue) {
      data['application[living_district_id]'] = null
      data['application[living_village_id]'] = null
    }

    if (attrName === 'application[living_district_id]' && currentValue)
      data['application[living_village_id]'] = null

    if (attrName === 'application[fdf_is_agreed]') return
    if (attrName === 'application[document_number]' && !currentValue) return

    $.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: `/application/steps/${this.step}`,
      data: {
        [attrName]: currentValue
      }
    })
  }

  updateBrowserStorage(key, value) {
    const whitelist = [
      'application[mobile_phone]',
      'application[full_name]',
      'application[date_of_birth]',
      'application[gender]',
      'application[salary]',
      'application[email]',
      'application[living_street_house_apt]',
      'application[guarantor_phone]',
      'application[document_number]'
    ]

    if (!whitelist.includes(key)) return

    const browserStorageList = JSON.parse(localStorage.getItem('browser_storage_list')) || {}
    delete browserStorageList[key]

    const data = {}

    const expiry = new Date()
    expiry.setMonth(expiry.getMonth() + 1)

    data[key] = {
      value,
      expiry
    }

    Object.assign(browserStorageList, data)

    localStorage.setItem('browser_storage_list', JSON.stringify(browserStorageList))
  }
}
